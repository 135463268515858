import { Injectable, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  GetUserDetails,
  LoginPayload,
  ResetPasswordWithToken,
  TwoFAPayload,
} from '../model/user-access-management-generic.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGraphqlService {
  constructor(private apollo: Apollo) {}

  public userDetail = signal<GetUserDetails>(this.userDetails);

  login(payload: LoginPayload) {
    const LOGIN_MUTATION = gql`
      mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
          token
          refreshToken
          is2faConfigured
          is2faEnabled
          user {
            id
            username
            firstName
            lastName
            isActive
            isSuperuser
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: LOGIN_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  twoFaAuthenticationData(payload: LoginPayload) {
    const SETUP_TWOFA_MUTATION = gql`
      mutation setup2fa($username: String!, $password: String!) {
        setup2fa(username: $username, password: $password) {
          qrCode
          setupKey
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SETUP_TWOFA_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  generate2faBackupCodeData() {
    const SETUP_TWOFA_MUTATION = gql`
      mutation generate2faBackupCode {
        generate2faBackupCode {
          backupCodes
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SETUP_TWOFA_MUTATION,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  verify2FABackupCode(payload: TwoFAPayload) {
    const VERIFY_TWOFA_MUTATION = gql`
      mutation verify2faBackupCode(
        $code: String!
        $username: String!
        $password: String!
      ) {
        verify2faBackupCode(
          code: $code
          username: $username
          password: $password
        ) {
          token
          refreshToken
          is2faConfigured
          is2faEnabled
          user {
            id
            username
            firstName
            lastName
            isActive
            isSuperuser
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: VERIFY_TWOFA_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  verifyTwoFa(payload: TwoFAPayload) {
    const VERIFY_TWOFA_MUTATION = gql`
      mutation verify2fa(
        $otp: String!
        $username: String!
        $password: String!
      ) {
        verify2fa(otp: $otp, username: $username, password: $password) {
          token
          refreshToken
          is2faConfigured
          is2faEnabled
          user {
            id
            username
            firstName
            lastName
            isActive
            isSuperuser
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: VERIFY_TWOFA_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  verifyOkta(accessToken: string) {
    const VERIFY_OKTA_MUTATION = gql`
      mutation verifyOkta($accessToken: String!) {
        verifyOkta(accessToken: $accessToken) {
          token
          refreshToken
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: VERIFY_OKTA_MUTATION,
      variables: { accessToken },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  verifyAD(accessToken: String) {
    const VERIFY_AD_MUTATION = gql`
      mutation verifyAD($accessToken: String!) {
        verifyAD(accessToken: $accessToken) {
          token
          refreshToken
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: VERIFY_AD_MUTATION,
      variables: { accessToken },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  requestPasswordReset(email: String) {
    const EMAIL_ADDRESS_MUTATION = gql`
      mutation requestPasswordReset($email: String!) {
        requestPasswordReset(email: $email)
      }
    `;
    return this.apollo.mutate({
      mutation: EMAIL_ADDRESS_MUTATION,
      variables: { email },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  resetPasswordWithToken(payload: ResetPasswordWithToken) {
    const Rest_Password_MUTATION = gql`
      mutation resetPasswordWithToken(
        $token: String!
        $newPassword: String!
        $confirmPassword: String!
      ) {
        resetPasswordWithToken(
          token: $token
          newPassword: $newPassword
          confirmPassword: $confirmPassword
        )
      }
    `;
    return this.apollo.mutate({
      mutation: Rest_Password_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  getRandomSecurityQuestion(email: String): Observable<any> {
    const GET_SECURITY_QUESTION = gql`
      query {
        getRandomSecurityQuestion(email: "${email}") {
          id question
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_SECURITY_QUESTION,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result: any) => result));
  }

  resetPasswordWithSecurityQuestion(payload: Object) {
    const RESET_SECURITY_MUTATION = gql`
      mutation resetPasswordWithSecurityQuestion(
        $email: String!
        $question: Int!
        $answer: String!
        $newPassword: String!
        $confirmPassword: String!
      ) {
        resetPasswordWithSecurityQuestion(
          email: $email
          question: $question
          answer: $answer
          newPassword: $newPassword
          confirmPassword: $confirmPassword
        ) {
          message
        }
      }
    `;
    return this.apollo.mutate({
      mutation: RESET_SECURITY_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  verify2faStatus(email: String): Observable<any> {
    const VERIFY_2FA_STATUS = gql`
      query {
        verify2faStatus(email: "${email}")
      }
    `;
    return this.apollo
      .watchQuery({
        query: VERIFY_2FA_STATUS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result: any) => result));
  }

  resetPasswordWith2fa(payload: Object) {
    const RESET_SECURITY_MUTATION = gql`
      mutation resetPasswordWith2fa(
        $email: String!
        $otp: String!
        $newPassword: String!
        $confirmPassword: String!
      ) {
        resetPasswordWith2fa(
          email: $email
          otp: $otp
          newPassword: $newPassword
          confirmPassword: $confirmPassword
        ) {
          message
        }
      }
    `;
    return this.apollo.mutate({
      mutation: RESET_SECURITY_MUTATION,
      variables: payload,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  }

  private get userDetails(): GetUserDetails {
    return JSON.parse(localStorage.getItem('predealuser') || '{}');
  }
}
